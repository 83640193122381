import { NavLink } from 'react-router-dom'

const Header = (props) => {
	console.log(JSON.stringify({props}));
	return (
		<div className="topnav">
			<NavLink to='/' activeClassName="active" exact >Home</NavLink>
			<NavLink to='/CarrierServices' activeClassName="active" >Carrier Services Tickets</NavLink>
			<NavLink to='/Risk' activeClassName="active" >Risk</NavLink>
			<NavLink to='/Profile' activeClassName="active" style={{float: 'right'}}>{props.profile.name}</NavLink>
		</div>
	);
}

export default Header