import Tickets from './Tickets/Tickets';
//import testTickets from './Example.json'

const CarrierServices = () => {
   return (

   <>
      <Tickets />
   </>
	)
}

export default CarrierServices