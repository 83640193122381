import "./Modal.css";
import useInput from "./use-input";
//import { useState } from 'react';

const Modal = (props) => {
	const type  = useInput(props.myForm.type,  value => value === 'PHONE' || value === 'EMAIL'); //TODO: Update validation

	let valueRegex;
	switch(type.value){
		case 'EMAIL':
			valueRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			break;
		case 'PHONE':
			valueRegex = /^[0-9]{10}$/;
			break;
		default:
			console.log(`Error: Unknown case: ${type.value}`);
			valueRegex = /^\S$/;
			break;
	};

	const value = useInput(props.myForm.value, value => value.match(valueRegex));
	const note  = useInput(props.myForm.note,  value => true);

	const sendUpdate = async (method, body) => {
		let url = 'https://api01.r2logistics.com/api/v1/risk/fraud/contacts';
		let request = {
			method: method,
			headers: new Headers({
				Accept: 'application/json',
				ContentType: 'application/json'
			}),
		}

		if(method === 'POST' || method === 'PATCH'){
			request.body = JSON.stringify(body);
		}

		if(method === 'DELETE' || method === 'PATCH'){
			if(typeof(body) === 'undefined' || typeof(body.contact_id) !== 'number'){
				console.error('Trying to delete without valid contact_id');
				return undefined;
			}
			url += '/'+body.contact_id.toString();
		}
		return await fetch(url, request);
	};

	const deleteItem = async (event) => {
		if(!window.confirm('Are you sure you want to delete this record?')){
			return;
		}
		const res = await sendUpdate('DELETE', { contact_id : props.myForm.contact_id});
		console.log('Delete:', res);
		//TODO: Check for errors before closing window and displaying them if appropriate
		if(res.status === 204){
			props.fetchContacts();
			props.setModal(false);
		}
		else if(res.status === 404){
			alert(`Contact ID ${props.myForm.contact_id} could not be found.`);
		}
		else {
			alert(`Unknown error.`);
			console.error(res);
		}
	};

	const submitForm = async (event) => {
		event.preventDefault();
		const data = {
			contact_id: props.myForm.contact_id,
			type: type.value,
			value: value.value,
			note: note.value
		};

		

		value.onBlur();
		note.onBlur();
		if(!type.isValid || !value.isValid || !note.isValid){
			console.log('Refusing to submit until errors are cleared.');
			return;
		}

		const method = typeof(data.contact_id) !== 'undefined' && typeof(data.contact_id) === 'number' ? 'PATCH' : 'POST';
		const res = await sendUpdate(method, data);
		console.log('res:',res);

		if(res.status === 200 || res.status === 204){
			props.fetchContacts();
			props.setModal(false);
		}
		if(res.status === 409){
			alert(`Duplicate entry.  ${data.type} ${data.value} already exists.`);
			console.log(res);
		}
		else{
			//TODO: Better error handling.  e.g., grab the error message and present it to the user.
			alert(`Error processing request.`);
			console.log(res);
		}
	};

	

	return(
		<div className="modal">
			<div className="overlay"></div>
			<div className="modal-content">
				<h2>{typeof(props.myForm.contact_id) === "undefined" ? "Add" : "Update"} Contact</h2>
				<form>
					<label>Type</label>
					<select name="type" value={type.value} onChange={type.onChange} onBlur={type.onBlur} >
						<option value="EMAIL">Email</option>
						<option value="PHONE">Phone</option>
					</select>
					<label>Value</label>
					<input name="value" type="text" value={value.value} onChange={value.onChange} onBlur={value.onBlur} />
					{value.hasError && <p className="error">Invalid Value</p>}
					<label>Note</label>
					<input name="note" type="text" value={note.value} onChange={note.onChange} onBlur={note.onBlur} />
					<button type="button" onClick={deleteItem}>Delete</button>
					<button type="submit" style={{float:"right", clear:"none"}} onClick={submitForm}>Submit</button>
				</form>
				<button className="close-modal" onClick={() => {props.setModal(false)}}>❌</button>
			</div>
		</div>
	);
}

export default Modal;