import { useState } from 'react'
//Notice: This doesn't work

const Dev = () => {
	const [state, setState] = useState({
		jwt: 'Enter JWT Here'
	});

	const handleChange = event => {
		console.log(JSON.stringify( Object.keys(event) ));
		//setState({[input]: e.target.value});
		setState({jwt: event.target.value});
	}

	const setCookie = async () => {
		const res = await fetch('https://api.home.r2logistics.com/v1/login/setCookie',{
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				Accept: 'application/json',
				authorizationToken: state.jwt
			})
		});
		const data = await res.json();

		console.log(data);
		//setTickets(data);
	};


	return (
		<div>
			<form>
				<label>JWT</label>
				<input type='text' value={state.jwt} onChange={handleChange} />
				<button onClick={setCookie}>Submit</button>
			</form>
		</div>
	)
}

export default Dev
