import { useState } from 'react';

const useInput = (defaultValue, validateValue) => {
	const [enteredValue, setEnteredValue] = useState(defaultValue || '');
	const [isTouched, setIsTouched] = useState(false);

	const valueIsValid = validateValue(enteredValue);
	const hasError = !valueIsValid && isTouched;

	const valueChangeHandler = (event) => {
		setEnteredValue(event.target.value);
	};
	const inputBlurHandler = (event) => {
		setIsTouched(true);
	};

	return {
		value: enteredValue,
		isValid: valueIsValid,
		hasError,
		onChange: valueChangeHandler,
		onBlur: inputBlurHandler
	};
};

export default useInput;