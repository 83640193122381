//import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'

const Table = (props) => {

	// const formatTrProps = (state = {}) => {
  //   console.log("qua");
  //   return { onClick: () => console.log("state", state) };
  // };
	const formatTrProps = (state = {}) => {
    console.log("qua");
    return { onClick: () => console.log("state", state) };
  };

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns: props.columns,
		data: props.rows,
		//formatRowProps: (state) => {formatTrProps(state)}
		formatRowProps: formatTrProps
	},
	useSortBy);

	
	return (
		<table {...getTableProps()} className='fraud_contacts'>
			<thead>
				{
					headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} className='fraud_contacts'>
							{
								headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())} className='fraud_contacts'>
										{column.render('Header')}
										<span>
											{column.isSorted ? (column.isSortedDesc ? '⬇️' : '⬆️'): ''}
										</span>
									</th>
								))
							}
						</tr>
					))
				}
			</thead>
			<tbody {...getTableBodyProps()}>
				{
					rows.map(row => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps(props.formatRowProps && props.formatRowProps(row))}>
								{
									row.cells.map(cell => {
										return (
											<td 
												{...cell.getCellProps()} 
												className='fraud_contacts'
												//onClick={props.openModal()}
											>
												{cell.render('Cell')}
											</td>
										)
									})
								}
							</tr>
						)
					})
				}
			</tbody>
		</table>
	)
}


export default Table