import FraudContacts from './FraudContacts/FraudContacts';

const Risk = () => {
	return (
		<>
			<FraudContacts/>
		</>
	)
}

export default Risk
