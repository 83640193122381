import Table from './Table';
import Modal from './Modal';
import { useEffect, useState, useCallback, useMemo } from 'react';

const FraudContacts = () => {
  const [contacts, setContacts] = useState({
    rows: [],
    columns: []
  });

  const [searchInput, setSearchInput] = useState("");

  const fetchContacts = useCallback(async () => {
    const res = await fetch('https://api01.r2logistics.com/api/v1/risk/fraud/contacts', {
      method: 'GET',
      //credentials: 'include',
      headers: new Headers({
        Accept: 'application/json',
      })
    });
    const data = parseContacts(await res.json());

    //TODO: Notify on errors
    //TODO: Change credentials to Same-Site for production
    switch (await res.status) {
      case 200:
        setContacts(data);
        break;
      case 401:
      case 403:
        console.error('Received authentication error when trying to get contacts.');
        //TODO: reauthorize
        break;
      default:
        console.error('Something else when trying to get contacts.');
      //TODO: something wonderful
    }
    return;
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  function parseContacts(contacts) {
    const headerReplacements = {
      type: 'Type',
      value: 'Value',
      note: 'Note',
      created: 'Created',
      blocked: 'Blocked',
    };

    const columns = contacts.columns
      .filter((currentValue) => {
        return typeof (headerReplacements[currentValue]) !== 'undefined'
      }, headerReplacements)
      .map((currentValue) => {
        return {
          accessor: currentValue,
          Header: headerReplacements[currentValue]
        };
      });

    const rows = contacts.rows
      .map((currentValue) => {
        return currentValue.reduce((accumulator, currentValue, currentIndex) => {
          accumulator[contacts.columns[currentIndex]] = currentValue;
          return accumulator;
        }, {});
      });

    return {
      rows,
      columns
    };
  }

  const [myModal, setModal] = useState(false);
  const [myForm, setForm] = useState({});

  const openModal = () => {
    setForm({
      type: 'EMAIL',
      value: '',
      note: ''
    });
    setModal(true);
  }

  const formatTrProps = (state = {}) => {
    return {
      onClick: () => {
        console.log(state.original);
        setForm(state.original);
        setModal(true);
      }
    };
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredRows = useMemo(() => {
    if (!searchInput) return contacts.rows;

    return contacts.rows.filter(row =>
      Object.values(row).some(
        val => val.toString().toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, contacts.rows]);

  return (
    <div className="fraud_contacts container">
      <div style={{ display: "inline-block" }}>
        <h1 className="fraud_contacts">Fraud Contacts</h1>
        <button type='button' onClick={openModal} style={{ float: "none" }}>Add New Contact</button>
        <input
          type="text"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search..."
          style={{ marginLeft: '10px' }}
        />
      </div>
      {myModal && <Modal setModal={setModal} myForm={myForm} setForm={setForm} fetchContacts={fetchContacts} />}
      <Table rows={filteredRows} columns={contacts.columns} formatRowProps={(state) => formatTrProps(state)} />
    </div>
  )
}

export default FraudContacts;
