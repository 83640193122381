import { useEffect, useState } from 'react';
//import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'

const Tickets = () => {
	const [tickets, setTickets] = useState({
		rows: [],
		columns: [],
		users: []
	});

	const fetchTickets = async () => {
		const res = await fetch('https://api.home.r2logistics.com/v1/carrier-services/tickets', {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				Accept: 'application/json',
			})
		});
		const data = parseTickets(await res.json());

		//TODO: Notify on errors
		//TODO: Change credentials to Same-Site for production
		switch (await res.status) {
			case 200:
				setTickets(data);
				break;
			case 401:
			case 403:
				console.error('Received authentication error when trying to get tickets.');
				//TODO: reauthorize
				break;
			default:
				console.error('Something else when trying to get tickets.');
			//TODO: something wonderful
		}
		return;
	};

	useEffect(() => { //TODO: Add try/catch
		fetchTickets();
		const interval = setInterval(() => fetchTickets(), (60 * 1000)); // Every minute (1000 milliseconds * 60)
		return () => clearInterval(interval);
	}, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns: tickets.columns,
		data: tickets.rows
	},
	useSortBy);

	return (
		<table {...getTableProps()} className='carrierServicesTickets'>
			<thead>
				{
					headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} className='carrierServicesTickets'>
							{
								headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())} className='carrierServicesTickets'>
										{column.render('Header')}
										<span>
											{column.isSorted ? (column.isSortedDesc ? '⬇️' : '⬆️'): ''}
										</span>
									</th>
								))
							}
						</tr>
					))
				}
			</thead>
			<tbody {...getTableBodyProps()}>
				{
					rows.map(row => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{
									row.cells.map(cell => {
										return (
											<td {...cell.getCellProps()} className='carrierServicesTickets'>
												{cell.render('Cell')}
											</td>
										)
									})
								}
							</tr>
						)
					})
				}
			</tbody>
		</table>
	)
}

function parseTickets(tickets) {
	let columns = [];
	tickets.columns.forEach(element => {
		columns.push({
			accessor: element.id.toString(),
			Header: element.title
		})
	});

	let users = {};
	tickets.users.forEach((user) => {
		users[user.id] = user.name;
	});

	let rows = [];
	tickets.rows.forEach(ticket => {
		let row = {};
		columns.forEach((column) => {
			const accessor = column.accessor;
			let value = ticket[accessor];
			if (accessor === 'requester' || accessor === 'assignee') {
				value = users[ticket[accessor + '_id']];
			}
			if (accessor === 'created') {
				const createTime = new Date(value);
				const currentTime = new Date();
				const ageInSeconds = (currentTime.getTime() - createTime.getTime()) / 1000;
				if (ageInSeconds < 60) {
					value = Math.floor(ageInSeconds) + ' seconds ago';
				}
				else if (ageInSeconds < 60 * 60) {
					value = Math.floor(ageInSeconds / 60) + ' minutes ago';
				}
				else if (
					createTime.getYear() === currentTime.getYear() &&
					createTime.getMonth() === currentTime.getMonth() &&
					createTime.getDate() === currentTime.getDate()
				) {
					value = 'Today ' + createTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
				}
				else {
					createTime.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
				}
			}
			row[accessor] = value;
		});
		rows.push(row)
	});

	return {
		rows,
		columns
	};
}
export default Tickets